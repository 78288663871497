#Table {
  border-collapse: collapse;
  width: 90%;
}

#Table-th,
#Table-td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

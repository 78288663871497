#ProjectBar {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: calc(100% - 2px);
  border-right: 2px solid rgb(216, 219, 223);
  background-color: rgb(253, 253, 255);
  color: #555;
}

#ProjectBar-Logo {
  position: absolute;
  top: 5px;
  left: 25px;
  height: 100px;
  width: calc(100% - 50px);
  background-image: url(../images/logo.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

/****************************************************************/

#ProjectBar .Button {
  position: absolute;
  top: 140px;
  left: 15px;
  width: calc(100% - 60px);
}

/****************************************************************/

#ProjectBar-ListHolder {
  position: absolute;
  top: 160px;
  left: 0px;
  height: calc(100% - 210px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

#ProjectBar-List {
  list-style-type: none;
  padding: 0px 15px;
  margin: 0px;
}

.ProjectBar-ProjectName {
  display: block;
  width: calc(100% - 14px);
  padding: 7px;
  margin: 3px 0px;

  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;

  cursor: pointer;
}

.ProjectBar-ProjectName.selected {
  background-color: #f0f0f0;
  font-weight: bold;
  color: #555;
}

/* .ProjectBar-ProjectName:hover {
  background-color: #88a8ff;
  color: white;
} */

.ProjectBar-Sublist {
  list-style-type: none;
  padding-left: 10px;
}

.ProjectBar-Sublist li {
  position: relative;
  padding: 7px;
  margin: 3px 0px;

  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;

  cursor: pointer;
}

.ProjectBar-Sublist li.selected {
  background-color: #f0f0f0;
  font-weight: bold;
  color: #555;
}

#EditButton {
  position: absolute;
  right: 10px; 
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/****************************************************************/
#ProjectBar-CustomAnalysisLayersSection,
#ProjectBar-DocsSection,
#ProjectBar-LOGOUTSection {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

#ProjectBar-CustomAnalysisLayersSection .Button,
#ProjectBar-DocsSection .Button,
#ProjectBar-LOGOUTSection .Button {
  position: absolute;
  top: 0;
  right: 15px;
  height: 35px;
  line-height: 35px;
  width: calc(100% - 60px);
}

#ProjectBar-CustomAnalysisLayersSection {
  height: 130px;
}
#ProjectBar-DocsSection {
  height: 90px;
}
#ProjectBar-LOGOUTSection {
  height: 50px;
}
/****************************************************************/

.MenuBar-NavIcon {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SmallMenu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SmallMenu-logo {
  height: 70px;
  width: calc(100% - 10px);
  background-image: url(../images/logo.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.SmallMenu-buttons {
  display: flex;
  width: 100%;
}
.SmallMenu-button {
  flex-grow: 1;
  padding: 10px 20px;
  border: none;
  /* padding: 20px 20px 20px 20px; */
  height: 40px;
  display: inline-block;
  color: #555;
  background-color: #ecf2ff;
  text-align: center;
  font-size: 20px;

  /* -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; */

  cursor: pointer;
  overflow: hidden;
}
.SmallMenu-button:hover {
  color: white;
  background-color: #88a8ff;
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}

#EditScenarioPopUp {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 11000;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
}

#EditScenarioPopUp_Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.1);
  width: 500px;
}

#EditScenarioPopUp-CloseButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
}

#EditScenarioPopUp-CloseButton div {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 30px;
  width: 30px;
  padding: 0;
}

#EditScenarioPopUp_ContentWrapper {
  position: relative;
  padding: 10px 20px 20px 20px;
}

#EditScenarioPopUp_Title {
  font-size: 19px;
  font-weight: 200;
}

#EditScenarioPopUp_Section {
  margin: 20px 0px 20px 0px;
  border-radius: 20px;
}

.TextInput-Background {
  width: 90% !important;
}

.EditScenarioPopUp_Tag {
  color: black;
  font-size: 15px;
  font-weight: 200;
  margin: 0px 0px 10px 0px;
}
#LoginScreen {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fafafa;
}

#LoginScreen-Holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  background-color: white;
  margin-bottom: 100px;
  border-radius: 35px;
  box-shadow: 0px 15px 30px 0px #c3c8d230;
}

#LoginScreen-Logo {
  width: 220px;
  height: 220px;
}

#LoginScreen-Title {
  text-align: center;
  color: #1c7b34;
  margin: 0px 25px 20px 25px;
  font-weight: 200;
  font-size: 18px;
}

.LoginScreen-Input {
  height: 40px;
  width: 250px;
  margin: 0px 30px 30px 30px;
  padding: 0px 5px;
  border: 0px;
  outline: 0px;
  background-color: transparent;
  font-size: 16px;
  border-bottom: 2px solid rgb(180, 180, 180);
  transition: all 300ms;
  color: #303030;
}

#LoginScreen-Holder .Button {
  margin: 0px 30px;
  width: calc(100% - 90px);
}

#LoginScreen-MoreInfo {
  text-align: center;
  color: rgb(100, 100, 100);
  margin: 20px 25px 40px 25px;
  font-weight: 200;
  font-size: 14px;
}

#LoginScreen-Link {
  color: #88a8ff;
}

.LoginScreen-ErrorMessage {
  position: relative; 
  color: red; 
  font-size: 14px;
  margin: 0 20px 20px 20px;
  text-align: center;
  padding: 5px 20px;
  background-color: #ffe6e6; 
  border: 1px solid #ffcccc; 
  border-radius: 5px;
}
#ContactScreen {
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

#ContactScreen-ContentHolder {
  display: flex;
  width: 100%;
  margin: 0 80px;
}

p {
  color: #555555;
}

#ContactPlanner-Container {
  width: 70%;
}

#ContactPersons-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
}

#ContactPersons-Container div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

#ContactPersons-Container h2 {
  margin-bottom: 0px;
  color: #5d87ff;
}

#ContactPersons-Container p {
  margin: 0;
}

iframe {
  width: 100%;
  border: none;
  height: calc(100vh - 250px);
}

.contact-person-img {
  width: 200px;
}

.MenuTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 20px 10px 20px;
}

.TitleContainer {
  display: flex;
  align-items: center;
}

.Title {
  margin-right: 10px;
  font-size: 18px;
  display: inline;
  text-transform: capitalize;
}

.TitleBtn {
  display: flex;
  border: none;
  width: 22px;
  height: 22px;
  padding: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  line-height: 4px;
  color: #ffffff;
  background-color: #5d87ff;
  text-align: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
}

.TitleBtn.transparent {
  color: black;
  background-color: transparent;
  width: 20px;
  height: 20px;
}

.TitleBtn:hover {
  color: white;
  background-color: #88a8ff;
}

.TitleActions {
  height: 100%;
  display: flex;
  gap: 5px;
}

.SortBtn, .FilterBtn {
  width: 22px;
  height: 22px;
  padding: 5px;
  background-color: #f0f0f0;
  color: #555555;
  border-radius: 6px;
  border: 0;
  cursor: pointer;
}

.DropdownList {
  width: 100%;
  position: absolute;
  top: 75%;
  left: 0;
  right: 0;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  list-style: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.DropdownItem {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #f1f1f1;
  font-size: 15px;
  align-items: flex-end;
}

.DropdownItem:hover {
  background-color: #5d87ff;
  color: white;
}

.DropdownItem.selected {
  background-color: #5d87ff;
  color: white;
}
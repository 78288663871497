.ScenarioResultScreen-Tab {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the buttons vertically */
  gap: 10px; /* Space between buttons */
  background-color: rgba(93, 135, 255, 0.3);
  padding: 10px 5px;
  margin: 0;
}

.tooltip .ScenarioResultScreen-Icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px; /* Adjust size as needed */
  padding: 4px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.ScenarioResultScreen-Icon-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.info-box {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  position: absolute;
  z-index: 1;
  display: none;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  white-space: nowrap;
  transform-origin: bottom right;
  bottom: 1.5em;
  right: 0.25em;
  margin-left: -0.5em;
  transition: opacity 0.3s;
  z-index: 1000;
}

.tooltip:hover .tooltiptext {
  display: block;
  opacity: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

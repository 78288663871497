#ScenarioResultScreen {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

#ScenarioResultScreen-ContentHolder {
  margin: 80px 10px 50px 60px;
}

#ScenarioResultScreen-ResultHolder {
  display: grid;
  grid-template-columns: repeat(auto-fit, 400px); /* width of the item */
  grid-auto-rows: 290px;
  gap: 30px;
}

@media (min-width: 1400px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 350px);
    grid-auto-rows: 250px;
  }
}
@media (min-width: 1500px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 375px);
    grid-auto-rows: 275px;
  }
}
@media (min-width: 1600px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 400px);
    grid-auto-rows: 300px;
  }
}
@media (min-width: 1700px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 425px);
    grid-auto-rows: 325px;
  }
}
@media (min-width: 1800px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 450px);
    grid-auto-rows: 350px;
  }
}
@media (min-width: 1900px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 500px);
    grid-auto-rows: 362px;
  }
}

@media (min-width: 2100px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 550px);
    grid-auto-rows: 400px;
  }
}
@media (min-width: 2400px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 600px);
    grid-auto-rows: 450px;
  }
}
@media (min-width: 2500px) {
  #ScenarioResultScreen-ResultHolder {
    grid-template-columns: repeat(auto-fit, 700px);
    grid-auto-rows: 500px;
  }
}
.ScenarioResultScreen-ResultItem {
  position: relative;
  background-color: rgb(253, 253, 255);
  border-radius: 20px;
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.15);
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0; /* Remove any padding to avoid scrollbar */
  margin: 0; /* Remove any margin to avoid scrollbar */
}

.spinner {
  margin-top: 27px;
  width: 19px;
  height: 19px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #5d87ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.upToDate {
  margin-top: 27px;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  background-color: rgb(240, 240, 240);
  color: #5d87ff;
}

.outOfDate {
  /* background-color: rgb(255, 215, 163); */
  background-color: #ff5d5d;
  color: white;
}

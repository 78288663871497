#EnlargedComponentPopup {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 10000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#EnlargedComponentPopup-Section {
  background-color: white;

  border: 2px solid var(--line-color);

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

#EnlargedComponentPopup-CloseButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
}


#EnlargedComponentPopup-CloseButton div {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 30px;
  width: 30px;
  padding: 0;
}

#EnlargedComponentPopup-ComponentContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#EnlargedComponentPopup-ComponentContainer div:nth-child(2) {
  display: flex;
  justify-content: center;
}

#EnlargedComponentPopup-Component {
  height: 90vh;
  width: 90vh;
  margin: 10px;
}

#EnlargedComponentPopup-Component div:nth-child(2) {
  width: 100% !important;
}

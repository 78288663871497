.ScenarioResultScreen-ResultItemTitle {
  position: absolute;
  font-size: 70%;
  padding: 7px 12px;
  z-index: 1000;
  font-weight: bold;
  color: #555;
}



#App-Container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

#App-MenuBar {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 250px;
  height: 100%;
}

#App-Screen {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: calc(100% - 250px);
}

#PoweredByTag {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  color: #000;
  font-size: 12px;
  z-index: 1000;
  opacity: 0.8; 
  transition: opacity 0.3s ease;
}

#PoweredByTag:hover {
  opacity: 1; 
}

#PoweredByLogo {
  width: 12px;
  height: auto;
  margin-top: 5px;
  margin-right: 5px;
}
